import React from "react"

const Content = ({ children }) => (
    <section className={`content-wrap default primary`} >
            <span itemprop="touristType" itemtype="https://schema.org/Audience" itemscope>
                <meta itemprop="audienceType" content="Yogis" />
                <meta itemprop="audienceType" content="Yoga tourism" />
                <meta itemprop="audienceType" content="Ayurveda tourism" />
                <meta itemprop="audienceType" content="Relaxation tourism" />
            </span>
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div 
                            className="content"
                            dangerouslySetInnerHTML={{ __html: children }}
                        ></div>
                    </div>
                </div>
            </div>
            {/* TODO <span itemscope itemprop="identifier" itemtype="https://schema.org/PropertyValue">
                <meta itemprop="propertyID" content="ISO 3166" />
                <meta itemprop="value" content="IT-88" />
            </span> */}
        </section>
)


export default Content
