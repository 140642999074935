import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from "react-helmet"
import { graphql } from 'gatsby'
import Layout from '../layouts/layout'
import Wrapper from '../templates/wrapper'
import Content from '../components/content/content-destination'


Content.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
}


const Destination = ({ data }) => {
  const { wpDestination: page } = data;

  let siteTitle = page && page.seo.title ? page.seo.title : `${page.title} `;

  return (
    <Layout page={page} type="boxed single full-width" headerStyle="article" section="Destinations & venues" source="destination" headerHeight="h35"> 
        <Helmet encodeSpecialCharacters={false}>
            <meta charSet="utf-8" />
            <title>{siteTitle}</title>
        </Helmet>
        <Wrapper>
            <Content>
              {page.content}
            </Content>
        </Wrapper>
    </Layout>
  )
}


Destination.propTypes = {
  data: PropTypes.object.isRequired,
}


export default Destination


export const pageQuery = graphql`
  query DestinationById($id: String!) {
    wpDestination(id: { eq: $id }) {
      title
      content
      excerpt
      featuredImage {
        node {
            sourceUrl
            title
            altText
            srcSet
            mediaDetails {
              sizes {
                width
                height
                sourceUrl
              }
            }
        }
      }
      seo {
        title
      }
      locationTopic {
        locationTopic
      }
      date(formatString: "Do MMMM YYYY")
    }
  }
`


